import DataGrid, {
  IDataGridProps,
} from 'components/organisms/DataGrid/DataGrid';
import {
  copyProfileDataGridCellTo,
  getProfileDataGridCellToString,
  getUpdateExtraRows,
  getUpdateRowWithValue,
} from 'components/organisms/ProfileDataGrid/helpers';
import ProfileDataGridEditRow from 'components/organisms/ProfileDataGrid/ProfileDataGridEditRow/ProfileDataGridEditRow';
import {
  IDataGridHandle,
  IDataGridSelectionContext,
} from 'interfaces/Component';
import { IProfileDataGridCell } from 'interfaces/Detail';
import { Context, forwardRef, Ref, useCallback, useMemo } from 'react';
import { Row, RowRendererProps } from 'react-data-grid';
import { TTimeZone } from 'types/DateTime';
import {
  TProfileDataGridColumn,
  TProfileDataGridRow,
  TProfileDataGridSummaryRow,
} from 'types/Detail';
import './styles.css';
import {
  PROFILE_PATH_START_KEY,
  PROFILE_PATH_STOP_KEY,
} from '../../../constants/Detail';
import { ZonedDateTime } from '../../../utils/zonedDateTime';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { ETheme } from '../../../enums/Style';

interface IProfileDataGridProps
  extends IDataGridProps<
    TProfileDataGridColumn,
    TProfileDataGridRow,
    TProfileDataGridSummaryRow,
    IProfileDataGridCell
  > {
  initialRows?: TProfileDataGridRow[];
  isEditable?: boolean;
  timeZone: TTimeZone;
}

const ProfileDataGrid = (
  { initialRows, isEditable, timeZone, ...rest }: IProfileDataGridProps,
  ref: Ref<IDataGridHandle>,
): JSX.Element => {
  const { currentTheme } = useThemeSwitcher();
  const getRowClass = useCallback(
    (row: TProfileDataGridRow) => {
      const startDate = row[PROFILE_PATH_START_KEY];
      const stopDate = row[PROFILE_PATH_STOP_KEY];

      if (startDate && stopDate && startDate.value && stopDate.value) {
        const startDateZonedDateTime = ZonedDateTime.parseIso(
          startDate.value as string,
          timeZone,
        );
        const stopDateZonedDateTime = ZonedDateTime.parseIso(
          stopDate.value as string,
          timeZone,
        );
        const currentHourStart = ZonedDateTime.now(timeZone)
          .withMinute(0)
          .withSeconds(0);
        const nextHourStart = ZonedDateTime.now(timeZone)
          .withMinute(0)
          .withSeconds(0)
          .add(1, 'hour');
        if (
          startDateZonedDateTime.isSameOrAfter(currentHourStart, 'seconds') &&
          stopDateZonedDateTime.isSameOrBefore(nextHourStart, 'seconds')
        ) {
          return currentTheme === ETheme.Light
            ? 'selectedRowLight'
            : 'selectedRowDark';
        } else {
          return '';
        }
      } else {
        return '';
      }
    },
    [currentTheme, timeZone],
  );

  const getRowRenderer = useCallback(
    (DataGridSelectionContext: Context<IDataGridSelectionContext>) =>
      isEditable === true
        ? (
            props: RowRendererProps<
              TProfileDataGridRow,
              TProfileDataGridSummaryRow
            >,
          ): JSX.Element => (
            <ProfileDataGridEditRow
              {...props}
              DataGridSelectionContext={DataGridSelectionContext}
              isRowSelected={true}
              initialDataSet={initialRows}
              rowClass={getRowClass}
            />
          )
        : (
            props: RowRendererProps<
              TProfileDataGridRow,
              TProfileDataGridSummaryRow
            >,
          ): JSX.Element => <Row {...props} rowClass={getRowClass}></Row>,
    [initialRows, isEditable, getRowClass],
  );

  const cellToString = useMemo(
    () => getProfileDataGridCellToString(timeZone),
    [timeZone],
  );

  const updateRowWithValue = useMemo(
    () => getUpdateRowWithValue(timeZone),
    [timeZone],
  );

  const updateExtraRows = useMemo(
    () => getUpdateExtraRows(updateRowWithValue),
    [updateRowWithValue],
  );

  return (
    <DataGrid
      {...rest}
      cellToString={cellToString}
      className='profile-data-grid'
      copyDataGridCellTo={copyProfileDataGridCellTo}
      getRowRenderer={getRowRenderer}
      ref={ref}
      updateExtraRows={updateExtraRows}
      updateRowWithValue={updateRowWithValue}
    />
  );
};

export default forwardRef(ProfileDataGrid);
