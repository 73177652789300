import { TRow, TSelectedCellsMap } from 'types/Component';
import { TProfileDataGridRow } from 'types/Detail';

export enum EProfileView {
  Profiles = 'profiles',
  Snapshots = 'snapshots',
}

export type TProfileDataGridCellId = string;

export interface IAddPhysicalSegmentsProfile {
  start: string | null;
  stop: string | null;
}

export interface IEditProfileDataGridRow {
  isSameTransAllocProfile: boolean;
  row: TProfileDataGridRow;
  updatedRow: TProfileDataGridRow;
}

export interface IRemoveRows {
  fromRow: TRow;
  toRow: TRow;
}
export interface IEditProfileDataGrid {
  addAfterRow?: TProfileDataGridRow;
  addBeforeRow?: TProfileDataGridRow;
  addExtraRows?: TProfileDataGridRow[];
  editProfileDataGridRows?: IEditProfileDataGridRow[];
  removeAfterRow?: TProfileDataGridRow;
  removeBeforeRow?: TProfileDataGridRow;
  removeRows?: IRemoveRows;
}

export interface IDataGridMenuItemProps {
  profileDataGridRow: TProfileDataGridRow;
  selectedCells: TSelectedCellsMap;
}
