import { AxiosResponse } from 'axios';
import Spinner from 'components/atoms/Spinner/Spinner';
import {
  COLUMN_GAP,
  ETAG_MANAGER_NARROW_VIEW_THRESHOLD_PX,
  LOAD_RETRY_DELAY_IN_MILLISECONDS,
  MAXIMUM_NUMBER_OF_LOAD_RETRIES,
  ROW_GAP,
} from 'components/organisms/ETagManager/constants';
import ETagManagerActionBar from 'components/organisms/ETagManager/ETagManagerActionBar';
import {
  getAllDistributedTagItemStateLoadTransforms,
  getMarketInformationViewLayoutMap,
  getNarrowMarketInformationViewLayoutMap,
  getNarrowViewColumnsRows,
  getNarrowViewLayoutMap,
  getUniqueETagNotifications,
  getViewColumnsRows,
  getViewLayoutMap,
  isAllValid,
  notificationsToStateLoadTransforms,
} from 'components/organisms/ETagManager/helpers';
import useGetCurrentETagTemplate from 'components/organisms/ETagManager/useGetCurrentETagTemplate';
import ViewManager from 'components/organisms/ETagManager/ViewManager';
import ETagWebSocket from 'components/services/ETagWebSocket/ETagWebSocket';
import { DEFAULT_DISPLAY_VIEW } from 'constants/Detail';
import { NO_OP_HANDLER } from 'constants/General';
import { EDetailIdType, EProfileFormat } from 'enums/Detail';
import { ECompositeState, EDistributedTagItem } from 'enums/ETag';
import { ERetreiveState, EUpdateState } from 'enums/General';
import { EPageMode } from 'enums/Page';
import { EDisplayView, EViewMode } from 'enums/View';
import usePageModeChange from 'hooks/usePageModeChange';
import usePrevious from 'hooks/usePrevious';
import useToEntityId from 'hooks/useToEntityId';
import {
  IUpdateUserUiConfigurationResponse,
  IUserUiConfiguration,
} from 'interfaces/Config';
import {
  IDistributedTagItemDetailStateLoadTransform,
  IDistributedTagItemRetrieveInformation,
  IETagManagerConfiguration,
  ILocationDescriptor,
} from 'interfaces/Detail';
import {
  IETagExtendedIdentifier,
  IETagNotification,
  IETagTemplate,
} from 'interfaces/ETag';
import { IContactInfo } from 'interfaces/General';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  detailEditETagDetail,
  detailRetrieveETagDetail,
  detailRetrieveETagDistributedTagItems,
  detailSetETagDetailViewMode,
  detailSetIsDetailDeleted,
  detailSetIsDetailValidating,
  detailSetSelectedProfileFormat,
  detailUpdateETagDetails,
} from 'reduxes/Detail/actions';
import { IDetailState, IETagDetail } from 'reduxes/Detail/types';
import { IToEntityUserState } from 'reduxes/User/types';
import { updateUserUiConfig } from 'services/configclient/config';
import { TTimeZone } from 'types/DateTime';
import { TErrorMessage } from 'types/Error';
import { TStateLoadTransform } from 'types/General';
import { TRootState } from 'types/Redux';
import { TToEntityId } from 'types/ToEntity';
import { TViewLayoutMap } from 'types/View';
import {
  cleanUpDetailState,
  getViewModeForTagPrimaryKey,
  isAutoNavigateToTag,
  isMarketInfosEditable,
  retrieveAndTransformETagDraftSummary,
  retrieveAndTransformETagTemplate,
  retrieveAndTransformMarketInfos,
  retrieveAndTransformRegistryEntities,
  retrieveAndTransformTransmissionPriorityConfigurations,
  transformAndUpdateETagDraft,
  transformAndUpdateETagTemplate,
  transformAndUpdateMarketInfos,
  updateDetailPageLocationDescriptor,
} from 'utils/detail';
import { captureError } from 'utils/error';
import {
  computeFullTagPrimaryKey,
  parseSecurityKeyFromFullTagPrimaryKey,
} from 'utils/eTag';
import { encodeIds, isEmptyValue, isSuccessStatus } from 'utils/general';
import {
  copyUserUiConfiguration,
  getDetailToEntityUserSelectedTimeZone,
  getUserContactInfo,
} from 'utils/user';

interface IETagManagerProps {
  encodedPermissionsId: string;
  onPageModeChange: (pageMode: EPageMode) => void;
  toEntityUserState?: IToEntityUserState;
  viewModeErrorMessage: TErrorMessage;
}

const retrieveETagManagerState = (state: TRootState) => {
  const {
    composite_state,
    config,
    draft_id,
    draftIsResolved,
    errorMessage,
    errorType,
    isDetailDeleted,
    isDetailEdited,
    isMarketInfoNeeded,
    marketInfos,
    pageMode,
    retrievingDetail,
    retrievingDistributedTagItems,
    security_key,
    selectedProfileFormat,
    start_date,
    tag_id,
    tag_primary_key,
    template_id,
    toEntity,
    updatingDetail,
    validations,
    viewMode,
  } = state.detail.present;

  const contactInfo: IContactInfo | undefined = getUserContactInfo(state);
  const isDetailLoading: boolean =
    (retrievingDetail !== ERetreiveState.NotRetrieving &&
      retrievingDetail !== ERetreiveState.RetrievingCompleted) ||
    (retrievingDistributedTagItems[EDistributedTagItem.LossAccountings]
      .retrieveState !== ERetreiveState.NotRetrieving &&
      retrievingDistributedTagItems[EDistributedTagItem.LossAccountings]
        .retrieveState !== ERetreiveState.RetrievingCompleted) ||
    (retrievingDistributedTagItems[EDistributedTagItem.MarketSegment]
      .retrieveState !== ERetreiveState.NotRetrieving &&
      retrievingDistributedTagItems[EDistributedTagItem.MarketSegment]
        .retrieveState !== ERetreiveState.RetrievingCompleted) ||
    (retrievingDistributedTagItems[EDistributedTagItem.PhysicalSegment]
      .retrieveState !== ERetreiveState.NotRetrieving &&
      retrievingDistributedTagItems[EDistributedTagItem.PhysicalSegment]
        .retrieveState !== ERetreiveState.RetrievingCompleted) ||
    (retrievingDistributedTagItems[EDistributedTagItem.PhysicalSegmentsProfiles]
      .retrieveState !== ERetreiveState.NotRetrieving &&
      retrievingDistributedTagItems[
        EDistributedTagItem.PhysicalSegmentsProfiles
      ].retrieveState !== ERetreiveState.RetrievingCompleted) ||
    (retrievingDistributedTagItems[EDistributedTagItem.Summary]
      .retrieveState !== ERetreiveState.NotRetrieving &&
      retrievingDistributedTagItems[EDistributedTagItem.Summary]
        .retrieveState !== ERetreiveState.RetrievingCompleted) ||
    (retrievingDistributedTagItems[EDistributedTagItem.TransactionStatuses]
      .retrieveState !== ERetreiveState.NotRetrieving &&
      retrievingDistributedTagItems[EDistributedTagItem.TransactionStatuses]
        .retrieveState !== ERetreiveState.RetrievingCompleted) ||
    (retrievingDistributedTagItems[EDistributedTagItem.TransmissionAllocations]
      .retrieveState !== ERetreiveState.NotRetrieving &&
      retrievingDistributedTagItems[EDistributedTagItem.TransmissionAllocations]
        .retrieveState !== ERetreiveState.RetrievingCompleted);
  const isDetailUpdating: boolean =
    updatingDetail !== EUpdateState.NotUpdating &&
    updatingDetail !== EUpdateState.UpdateCompleted;
  const timeZone: TTimeZone = getDetailToEntityUserSelectedTimeZone(state);

  let isETagDetailLoadFailure: boolean = false;
  let isETagDetailLoadSuccess: boolean = true;

  for (let distributedTagItem in retrievingDistributedTagItems) {
    const {
      errorMessage,
      retrieveState,
    }: IDistributedTagItemRetrieveInformation =
      retrievingDistributedTagItems[distributedTagItem as EDistributedTagItem];

    if (
      retrieveState === ERetreiveState.NotRetrieving &&
      !isEmptyValue(errorMessage)
    ) {
      isETagDetailLoadFailure = true;
    }

    if (retrieveState !== ERetreiveState.RetrievingCompleted) {
      isETagDetailLoadSuccess = false;
    }
  }

  return {
    composite_state,
    config,
    contactInfo,
    draft_id,
    draftIsResolved,
    errorMessage,
    errorType,
    isDetailDeleted,
    isDetailEdited,
    isDetailLoading,
    isDetailUpdating,
    isETagDetailLoadFailure,
    isETagDetailLoadSuccess,
    isMarketInfoNeeded,
    marketInfos,
    pageMode,
    security_key,
    selectedProfileFormat,
    start_date,
    tag_id,
    tag_primary_key,
    template_id,
    timeZone,
    toEntity,
    uiTagId: tag_id === null ? '' : tag_id.ui_tag_id,
    validations,
    viewMode,
  };
};

const ETagManager = ({
  encodedPermissionsId,
  onPageModeChange,
  toEntityUserState,
  viewModeErrorMessage,
}: IETagManagerProps): JSX.Element => {
  const dispatch = useDispatch();
  const {
    composite_state,
    config,
    contactInfo,
    draft_id,
    draftIsResolved,
    errorMessage,
    errorType,
    isDetailDeleted,
    isDetailEdited,
    isETagDetailLoadFailure,
    isETagDetailLoadSuccess,
    isDetailLoading,
    isDetailUpdating,
    marketInfos,
    pageMode,
    security_key,
    selectedProfileFormat,
    start_date,
    tag_id,
    tag_primary_key,
    template_id,
    timeZone,
    toEntity,
    uiTagId,
    validations,
    viewMode,
  } = useSelector(retrieveETagManagerState);

  const history = useHistory();
  const { search } = useLocation();
  const getCurrentETagTemplate = useGetCurrentETagTemplate();
  const pageModeChange = usePageModeChange(search);
  const [eTagManagerConfiguration, setETagManagerConfiguration] = useState<
    IETagManagerConfiguration | undefined
  >();
  const [allowDisplayViewSelection, setAllowDisplayViewSelection] =
    useState<boolean>(viewMode === EViewMode.ReviewETag);
  const [selectedDisplayView, setSelectedDisplayView] = useState<
    EDisplayView | undefined
  >();
  const [numberOfDetailUpdates, setNumberOfDetailUpdates] = useState<number>(0);
  const [numberOfRefreshes, setNumberOfRefreshes] = useState<number>(0);
  const previousIsDetailDeleted: boolean | undefined =
    usePrevious(isDetailDeleted);
  const previousIsDetailUpdating: boolean | undefined =
    usePrevious(isDetailUpdating);
  const handleETagNotificationRef =
    useRef<(eTagNotification: IETagNotification) => void>(NO_OP_HANDLER);
  const eTagUpdatesRef = useRef<IETagNotification[] | null>(null);
  const loadRetryCountRef = useRef<number>(0);
  const loadRetryTimeoutRef = useRef<number | null>(null);
  const [windowInnerWidth, setWindowInnerWidth] = useState<number>(
    window.innerWidth,
  );

  const isETagWebSocketEnabled: boolean =
    viewMode !== EViewMode.EditETagTemplate &&
    viewMode !== EViewMode.ReviewETagTemplate;

  useEffect(() => {
    if (composite_state && composite_state !== ECompositeState.Draft) {
      dispatch(
        detailSetSelectedProfileFormat({
          selectedProfileFormat:
            eTagManagerConfiguration?.defaultProfileFormat ||
            EProfileFormat.StartStop,
        }),
      );
    }
  }, [
    composite_state,
    dispatch,
    eTagManagerConfiguration?.defaultProfileFormat,
  ]);

  useEffect(() => {
    if (toEntityUserState?.userUiConfig !== undefined) {
      const eTagManagerConfiguration: IETagManagerConfiguration = {
        ...toEntityUserState.userUiConfig,
      };

      setSelectedDisplayView(
        viewMode === EViewMode.ReviewETag
          ? toEntityUserState?.userUiConfig?.defaultDisplayView === undefined
            ? DEFAULT_DISPLAY_VIEW
            : toEntityUserState.userUiConfig.defaultDisplayView
          : EDisplayView.Grid,
      );

      setETagManagerConfiguration(eTagManagerConfiguration);
    }
  }, [toEntityUserState?.userUiConfig, viewMode]);

  useEffect(() => {
    setSelectedDisplayView(
      viewMode === EViewMode.ReviewETag
        ? eTagManagerConfiguration?.defaultDisplayView === undefined
          ? DEFAULT_DISPLAY_VIEW
          : eTagManagerConfiguration?.defaultDisplayView
        : EDisplayView.Grid,
    );
  }, [eTagManagerConfiguration?.defaultDisplayView, viewMode]);

  useEffect(() => {
    const resizeHandler = () => {
      setWindowInnerWidth(window.innerWidth);
    };
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  useEffect(() => {
    if (
      pageMode === EPageMode.Edit &&
      viewMode === EViewMode.EditETagDraft &&
      !isDetailLoading &&
      composite_state !== null &&
      composite_state !== ECompositeState.Draft
    ) {
      onPageModeChange(EPageMode.Review);
    }
  }, [composite_state, isDetailLoading, onPageModeChange, pageMode, viewMode]);

  useEffect(() => {
    if (draft_id !== null && draftIsResolved !== undefined && draftIsResolved) {
      const urlSearchParams = new URLSearchParams(search);

      if (isAutoNavigateToTag(urlSearchParams)) {
        const fullTagPrimaryKey = computeFullTagPrimaryKey(
          tag_id,
          security_key,
        );

        if (fullTagPrimaryKey !== undefined) {
          const locationDescriptor: ILocationDescriptor =
            updateDetailPageLocationDescriptor(search, {
              tagPrimaryKey: fullTagPrimaryKey,
            });

          history.push(locationDescriptor);
        } else {
          captureError(
            new Error(
              'Could not compute full tag primary key from draft tag_id and security_key in resolved draft. Cannot open tag detail page.',
            ),
          );
        }
      }
    }
  }, [draft_id, draftIsResolved, history, search, security_key, tag_id]);

  const retrieveETagDetail = useCallback(
    (
      detailIdType: EDetailIdType,
      stateLoadTransform: TStateLoadTransform<IDetailState>[],
      shouldCaptureError?: boolean,
    ) =>
      dispatch(
        detailRetrieveETagDetail(
          detailIdType,
          stateLoadTransform,
          shouldCaptureError,
        ),
      ),
    [dispatch],
  );

  const retrieveETagDistributedTagItems = useCallback(
    (
      distributedTagItemDetailStateLoadTransform: IDistributedTagItemDetailStateLoadTransform[],
    ) =>
      dispatch(
        detailRetrieveETagDistributedTagItems(
          distributedTagItemDetailStateLoadTransform,
        ),
      ),
    [dispatch],
  );

  const setIsDetailDeleted = useCallback(
    (isDetailDeleted: boolean) =>
      dispatch(detailSetIsDetailDeleted({ isDetailDeleted })),
    [dispatch],
  );

  const setIsDetailValidating = useCallback(
    (isDetailValidating: boolean) =>
      dispatch(detailSetIsDetailValidating({ isDetailValidating })),
    [dispatch],
  );

  const updateETagDraft = useCallback(() => {
    // In order to prevent a notification message appearing after each save,
    // we reduce this count by 1 to account for an expected etag websocket
    // message corresponding to the successful update to the draft.
    setNumberOfDetailUpdates(
      (previousNumberOfDetailUpdates: number): number =>
        previousNumberOfDetailUpdates - 1,
    );

    dispatch(detailUpdateETagDetails(transformAndUpdateETagDraft(timeZone)));

    dispatch(detailUpdateETagDetails(transformAndUpdateMarketInfos()));
  }, [dispatch, timeZone]);

  const updateETagTemplate = useCallback(() => {
    dispatch(detailUpdateETagDetails(transformAndUpdateETagTemplate(timeZone)));

    dispatch(detailUpdateETagDetails(transformAndUpdateMarketInfos()));
  }, [dispatch, timeZone]);

  const cleanUpETag = useCallback(
    (clearIsDetailEdited: boolean) => {
      const eTagDetail: IETagDetail = {
        stateTransform: cleanUpDetailState(),
      };

      if (clearIsDetailEdited) {
        eTagDetail.isDetailEdited = false;
      }

      return dispatch(detailEditETagDetail(eTagDetail));
    },
    [dispatch],
  );

  const toEntityId: TToEntityId = useToEntityId(toEntity);

  const updateETagDetail = useCallback(
    (shouldCaptureError?: boolean) => {
      const detailStateLoadTransforms: TStateLoadTransform<IDetailState>[] = [];

      if (!isEmptyValue(draft_id)) {
        detailStateLoadTransforms.push(
          retrieveAndTransformRegistryEntities(toEntityId),
          retrieveAndTransformETagDraftSummary(toEntityId, draft_id),
          retrieveAndTransformMarketInfos(toEntityId, draft_id),
        );

        retrieveETagDetail(
          EDetailIdType.DraftId,
          detailStateLoadTransforms,
          shouldCaptureError,
        );
      } else if (!isEmptyValue(tag_primary_key)) {
        detailStateLoadTransforms.push(
          retrieveAndTransformRegistryEntities(toEntityId),
          retrieveAndTransformMarketInfos(toEntityId, null, tag_primary_key),
        );
        retrieveETagDetail(
          EDetailIdType.TagPrimaryKey,
          detailStateLoadTransforms,
          shouldCaptureError,
        );
        retrieveETagDistributedTagItems(
          getAllDistributedTagItemStateLoadTransforms(
            toEntityId,
            tag_primary_key!,
          ),
        );
      } else if (!isEmptyValue(template_id)) {
        detailStateLoadTransforms.push(
          retrieveAndTransformRegistryEntities(toEntityId),
          retrieveAndTransformETagTemplate(toEntityId, template_id!),
          retrieveAndTransformMarketInfos(
            toEntityId,
            null,
            undefined,
            template_id,
          ),
          retrieveAndTransformTransmissionPriorityConfigurations(
            toEntityId,
            template_id,
          ),
        );

        retrieveETagDetail(
          EDetailIdType.TemplateId,
          detailStateLoadTransforms,
          false,
        );
      }
    },
    [
      draft_id,
      retrieveETagDetail,
      retrieveETagDistributedTagItems,
      tag_primary_key,
      template_id,
      toEntityId,
    ],
  );

  useEffect(updateETagDetail, [updateETagDetail]);

  const setViewMode = useCallback(
    (viewMode: EViewMode) =>
      dispatch(detailSetETagDetailViewMode({ viewMode })),
    [dispatch],
  );

  const handleRefresh = useCallback(async () => {
    //Passing this changed value to the ScheduleSubmission component triggers it to rerender on a view refresh
    //instead of just on a hard page refresh. This value is reset to 0 on a hard page refresh.
    setNumberOfRefreshes(numberOfRefreshes + 1);
    if (draft_id === null) {
      if (tag_primary_key !== undefined) {
        if (pageMode === EPageMode.Edit) {
          let updatedViewMode: EViewMode = viewMode;

          try {
            updatedViewMode = await getViewModeForTagPrimaryKey(
              tag_primary_key,
              toEntityId,
              timeZone,
            );

            if (updatedViewMode !== viewMode) {
              setViewMode(updatedViewMode);

              if (isDetailEdited) {
                cleanUpETag(true);

                updateETagDetail();
              }
            }
          } catch (error: any) {
            pageModeChange(EPageMode.Review);
          }
        }

        if (eTagUpdatesRef.current === null) {
          retrieveETagDistributedTagItems(
            getAllDistributedTagItemStateLoadTransforms(
              toEntityId,
              tag_primary_key,
            ),
          );
        } else {
          retrieveETagDistributedTagItems(
            notificationsToStateLoadTransforms(
              getUniqueETagNotifications(eTagUpdatesRef.current),
              toEntityId,
              tag_primary_key,
            ),
          );

          eTagUpdatesRef.current = null;
        }

        setNumberOfDetailUpdates((): number => 0);
      } else if (template_id !== undefined) {
        updateETagDetail();
      }
    } else {
      updateETagDetail();

      setNumberOfDetailUpdates((): number => 0);
    }

    loadRetryTimeoutRef.current = null;
  }, [
    cleanUpETag,
    draft_id,
    isDetailEdited,
    numberOfRefreshes,
    pageMode,
    pageModeChange,
    retrieveETagDistributedTagItems,
    setViewMode,
    tag_primary_key,
    template_id,
    timeZone,
    toEntityId,
    updateETagDetail,
    viewMode,
  ]);

  useEffect(() => {
    if (
      isETagDetailLoadFailure &&
      loadRetryCountRef.current < MAXIMUM_NUMBER_OF_LOAD_RETRIES
    ) {
      loadRetryCountRef.current += 1;

      loadRetryTimeoutRef.current = window.setTimeout(() => {
        handleRefresh();
      }, LOAD_RETRY_DELAY_IN_MILLISECONDS);
    }
  }, [handleRefresh, isETagDetailLoadFailure]);

  useEffect(() => {
    if (isETagDetailLoadSuccess) {
      loadRetryCountRef.current = 0;
    }
  }, [isETagDetailLoadSuccess]);

  useEffect(() => {
    if (isETagWebSocketEnabled) {
      handleETagNotificationRef.current = (
        eTagNotification: IETagNotification,
      ) => {
        if (loadRetryTimeoutRef.current === null) {
          const hasDraftId: boolean = !isEmptyValue(eTagNotification.draft_id);
          const hasTagPrimaryKey: boolean = !isEmptyValue(
            eTagNotification.tag_primary_key,
          );

          if (
            (hasDraftId && eTagNotification.draft_id === draft_id) ||
            (hasTagPrimaryKey &&
              eTagNotification.tag_primary_key === tag_primary_key)
          ) {
            setNumberOfDetailUpdates(
              (previousNumberOfDetailUpdates: number): number =>
                previousNumberOfDetailUpdates + 1,
            );

            if (!hasDraftId) {
              if (eTagUpdatesRef.current === null) {
                eTagUpdatesRef.current = [eTagNotification];
              } else {
                eTagUpdatesRef.current.push(eTagNotification);
              }
            }
          }
        }
      };
    }
  }, [draft_id, isETagWebSocketEnabled, tag_primary_key]);

  const blockHandler = useCallback(() => {
    if (isDetailEdited) {
      if (
        window.confirm('Leave site? Changes that you made may not be saved.')
      ) {
        // Resets isDetailEdited
        cleanUpETag(true);

        // Reloads all the ETag data
        updateETagDetail();

        // Block handler is expected to return anything but false when we
        // want to allow navigation to proceed.
        return;
      }

      return false;
    }
  }, [cleanUpETag, isDetailEdited, updateETagDetail]);

  useEffect(() => {
    // React router needs to have an explicit handler for preventing navigation
    // since it by-passes the standard browser navigation and thus does NOT
    // trigger the beforeunload event.
    const unblock = history.block(blockHandler);

    const unloadHandler = (event: Event) => {
      event.preventDefault();
      // noinspection JSDeprecatedSymbols
      (event || window.event).returnValue = true;
      return '';
    };

    if (isDetailEdited) {
      window.addEventListener('beforeunload', unloadHandler);
    }

    return () => {
      unblock();

      window.removeEventListener('beforeunload', unloadHandler);
    };
  }, [blockHandler, history, isDetailEdited]);

  useEffect(() => {
    switch (viewMode) {
      case EViewMode.EditETagAdjustment:
      case EViewMode.EditETagAdjustmentWithATF:
      case EViewMode.EditETagCorrection:
      case EViewMode.EditETagDraft:
      case EViewMode.EditETagTemplate: {
        setAllowDisplayViewSelection(false);
        setSelectedDisplayView(EDisplayView.Grid);
        break;
      }
      case EViewMode.ReviewETag: {
        setAllowDisplayViewSelection(true);
        break;
      }
      case EViewMode.ReviewETagDraft:
      case EViewMode.ReviewETagTemplate: {
        setAllowDisplayViewSelection(false);
        setSelectedDisplayView(EDisplayView.Grid);
        break;
      }
      default: {
        break;
      }
    }
  }, [viewMode]);

  const handleDeleteSuccess = () => {
    setIsDetailDeleted(true);
  };

  const saveETagDetail = useCallback(
    () => {
      setIsDetailValidating(true);

      if (isAllValid(validations)) {
        setIsDetailValidating(false);

        // Leave isDetailEdited untouched so that any errors in the updates
        // will still allow the user to try to save again.
        cleanUpETag(false);

        if (draft_id !== null) {
          updateETagDraft();
        } else if (template_id !== undefined) {
          updateETagTemplate();
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [draft_id, template_id, validations],
  );

  const eTagTemplate: IETagTemplate | undefined = useMemo(
    () =>
      template_id === undefined || viewMode !== EViewMode.ReviewETagTemplate
        ? undefined
        : getCurrentETagTemplate(),
    [getCurrentETagTemplate, template_id, viewMode],
  );

  const updateETagManagerConfiguration = useCallback(
    async (eTagManagerConfiguration: IETagManagerConfiguration) => {
      // We won't try/catch this operation so that errors can propagate to the
      // callers of updateETagManagerConfiguration
      const updatedUserUiConfiguation: IUserUiConfiguration =
        copyUserUiConfiguration(eTagManagerConfiguration);

      const response: AxiosResponse<IUpdateUserUiConfigurationResponse> =
        await updateUserUiConfig(toEntityId, updatedUserUiConfiguation);

      const updateUserUiConfigurationResponse: IUpdateUserUiConfigurationResponse =
        response.data;

      if (!isSuccessStatus(response.status)) {
        throw new Error(updateUserUiConfigurationResponse.errorMessage!);
      }

      setETagManagerConfiguration(eTagManagerConfiguration);
    },
    [toEntityId],
  );

  const isNarrowView = useMemo(
    () => windowInnerWidth < ETAG_MANAGER_NARROW_VIEW_THRESHOLD_PX,
    [windowInnerWidth],
  );

  const { viewColumns, viewRows } = useMemo(
    () =>
      isNarrowView
        ? getNarrowViewColumnsRows(viewMode)
        : getViewColumnsRows(viewMode),
    [isNarrowView, viewMode],
  );

  const initialViewLayoutMap: TViewLayoutMap = useMemo(() => {
    if (isNarrowView) {
      const viewLayoutMap: TViewLayoutMap = getNarrowViewLayoutMap();

      if (selectedDisplayView === EDisplayView.Graph) {
        delete viewLayoutMap.marketPath;
        delete viewLayoutMap.physicalPath;
        delete viewLayoutMap.profileInformation;
        viewLayoutMap.pathDiagram.layoutGrid.gridColumn = '1 / 3';
        viewLayoutMap.pathDiagram.layoutGrid.gridRow = '1 / 3';
      } else if (selectedDisplayView === EDisplayView.Grid) {
        if (
          config !== undefined &&
          (marketInfos.length > 0 ||
            (isMarketInfosEditable(viewMode) &&
              config.market_info_markets !== undefined &&
              config.market_info_markets.length > 0))
        ) {
          viewLayoutMap.marketInformation =
            getNarrowMarketInformationViewLayoutMap();
          viewLayoutMap.marketPath.layoutGrid.gridRow = '7 / 8';
        }

        if (
          !(
            viewMode === EViewMode.EditETagAdjustment ||
            viewMode === EViewMode.EditETagAdjustmentWithATF ||
            viewMode === EViewMode.EditETagCorrection ||
            viewMode === EViewMode.ReviewETag
          )
        ) {
          viewLayoutMap.profileInformation.layoutGrid.gridColumn = '1 / 3';
          viewLayoutMap.marketPath.layoutGrid.gridRow = '5 / 7';
          if (
            config !== undefined &&
            (marketInfos.length > 0 ||
              (isMarketInfosEditable(viewMode) &&
                config.market_info_markets !== undefined &&
                config.market_info_markets.length > 0))
          ) {
            viewLayoutMap.marketInformation.layoutGrid.gridRow = '6 / 7';
            viewLayoutMap.marketPath.layoutGrid.gridRow = '5 / 6';
          }
          viewLayoutMap.summaryInformation.layoutGrid.gridRow = '5 / 7';
          delete viewLayoutMap.requestsInformation;
        }

        delete viewLayoutMap.pathDiagram;
        delete viewLayoutMap.profileGraph;
      }
      return viewLayoutMap;
    } else {
      const viewLayoutMap: TViewLayoutMap = getViewLayoutMap();

      if (selectedDisplayView === EDisplayView.Graph) {
        delete viewLayoutMap.marketPath;
        delete viewLayoutMap.physicalPath;
        delete viewLayoutMap.profileInformation;
        if (
          config !== undefined &&
          (marketInfos.length > 0 ||
            (isMarketInfosEditable(viewMode) &&
              config.market_info_markets !== undefined &&
              config.market_info_markets.length > 0))
        ) {
          viewLayoutMap.marketInformation = getMarketInformationViewLayoutMap();
        }
      } else if (selectedDisplayView === EDisplayView.Grid) {
        if (
          config !== undefined &&
          (marketInfos.length > 0 ||
            (isMarketInfosEditable(viewMode) &&
              config.market_info_markets !== undefined &&
              config.market_info_markets.length > 0))
        ) {
          viewLayoutMap.marketInformation = getMarketInformationViewLayoutMap();

          viewLayoutMap.marketPath.layoutGrid.gridRow = '1 / 2';
          if (viewMode === EViewMode.ReviewETag) {
            // We need to set the grid prop to auto to fix the market information box in the column
            const viewManagerElement =
              document.getElementsByClassName('view-manager');
            if (viewManagerElement && viewManagerElement.length > 0) {
              (viewManagerElement[0] as any).style.gridAutoRows = 'auto';
              (viewManagerElement[0] as any).style.gridTemplateRows =
                'repeat(4, 1fr)';
            }

            viewLayoutMap.summaryInformation.layoutGrid.gridRow = '1 / 2';
            viewLayoutMap.requestsInformation.layoutGrid.gridRow = '2 / 3';
            viewLayoutMap.marketPath.layoutGrid.gridRow = '3 / 4';
            viewLayoutMap.marketInformation.layoutGrid.gridRow = '4 / 5';
            const marketInfoView = document.getElementsByClassName(
              'market-information-view',
            );
            setTimeout(() => {
              if (marketInfoView && marketInfoView.length > 0) {
                (marketInfoView[0] as any).style.height = '99%';
              }
            }, 100);
          }
        }

        if (
          !(
            viewMode === EViewMode.EditETagAdjustment ||
            viewMode === EViewMode.EditETagAdjustmentWithATF ||
            viewMode === EViewMode.EditETagCorrection ||
            viewMode === EViewMode.ReviewETag
          )
        ) {
          viewLayoutMap.profileInformation.layoutGrid.gridColumn = '1 / 4';
          viewLayoutMap.marketPath.layoutGrid.gridRow = '3 / 5';
          delete viewLayoutMap.requestsInformation;
        }

        delete viewLayoutMap.pathDiagram;
        delete viewLayoutMap.profileGraph;
      }

      return viewLayoutMap;
    }
  }, [config, isNarrowView, marketInfos.length, selectedDisplayView, viewMode]);
  // change for deployment

  const securityKey: string | undefined = useMemo(
    () =>
      isEmptyValue(security_key)
        ? parseSecurityKeyFromFullTagPrimaryKey(tag_primary_key)
        : security_key!,
    [tag_primary_key, security_key],
  );

  const eTagExtendedIdentifier: IETagExtendedIdentifier = useMemo(
    () => ({
      composite_state,
      draft_id,
      tag_primary_key,
      ui_tag_id: uiTagId,
    }),
    [composite_state, draft_id, tag_primary_key, uiTagId],
  );

  const isSaveDisabled: boolean = useMemo(
    () => !isAllValid(validations),
    [validations],
  );

  return (
    <>
      {eTagManagerConfiguration === undefined ||
      loadRetryTimeoutRef.current !== null ||
      (loadRetryCountRef.current > 0 && isDetailLoading) ? (
        <Spinner
          message={
            loadRetryCountRef.current > 0
              ? 'Tag is being processed by E-Tag Authority'
              : undefined
          }
        />
      ) : (
        <>
          <ETagManagerActionBar
            allowDisplayViewSelection={allowDisplayViewSelection}
            compositeState={composite_state}
            defaultContactInfo={contactInfo}
            encodedPermissionsId={encodeIds([
              encodedPermissionsId,
              'actionBar',
            ])}
            errorMessage={errorMessage}
            errorType={errorType}
            eTagExtendedIdentifier={eTagExtendedIdentifier}
            eTagManagerConfiguration={eTagManagerConfiguration}
            eTagTemplate={eTagTemplate}
            isDetailDeleted={isDetailDeleted}
            isDetailEdited={isDetailEdited}
            isDetailLoading={isDetailLoading}
            isDetailUpdating={isDetailUpdating}
            isSaveDisabled={isSaveDisabled}
            numberOfDetailUpdates={numberOfDetailUpdates}
            numberOfRefreshes={numberOfRefreshes}
            onDraftDeleteSuccess={handleDeleteSuccess}
            onTemplateActivateSuccess={updateETagDetail}
            onTemplateDeleteSuccess={handleDeleteSuccess}
            onPageModeChange={onPageModeChange}
            onRefresh={handleRefresh}
            onSave={saveETagDetail}
            pageMode={pageMode}
            previousIsDetailDeleted={previousIsDetailDeleted}
            previousIsDetailUpdating={previousIsDetailUpdating}
            securityKey={securityKey}
            selectedDisplayView={selectedDisplayView}
            selectedProfileFormat={selectedProfileFormat}
            setNumberOfDetailUpdates={setNumberOfDetailUpdates}
            setSelectedDisplayView={setSelectedDisplayView}
            startDate={start_date}
            tagId={tag_id}
            tagPrimaryKey={tag_primary_key}
            timeZone={timeZone}
            toEntity={toEntity}
            toEntityId={toEntityId}
            updateETagManagerConfiguration={updateETagManagerConfiguration}
            viewMode={viewMode}
            viewModeErrorMessage={viewModeErrorMessage}
          />
          <ViewManager
            columnGap={COLUMN_GAP}
            columns={viewColumns}
            encodedPermissionsId={encodeIds([
              encodedPermissionsId,
              'viewManager',
            ])}
            initialViewLayoutMap={initialViewLayoutMap}
            isNarrowLayout={isNarrowView}
            preventAutoEdits={!isEmptyValue(viewModeErrorMessage)}
            rowGap={ROW_GAP}
            rows={viewRows}
          />
          {isETagWebSocketEnabled ? (
            <ETagWebSocket
              handleETagNotificationRef={handleETagNotificationRef}
              toEntityId={toEntityId}
            />
          ) : null}
        </>
      )}
    </>
  );
};

export default ETagManager;
