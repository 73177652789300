import { QuestionCircleOutlined } from '@ant-design/icons';
import { Radio as AntDesignRadio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import { IOption } from 'interfaces/Component';
import { useCallback } from 'react';
import styled from 'styled-components';
import Tooltip from '../Tooltip/Tooltip';

const { Group: AntDesignRadioGroup } = AntDesignRadio;

const Radio = styled(AntDesignRadio)`
  .ant-radio {
    vertical-align: middle;
  }
`;

export interface IRadioGroupProps<T> {
  className?: string;
  isDisabled?: boolean;
  onChange?: (value: T | undefined) => void;
  options: IOption<T>[];
  value?: T;
  valueToUid: (value: T) => string;
}

const RadioGroup = <T extends any>(props: IRadioGroupProps<T>): JSX.Element => {
  const { className, isDisabled, onChange, options, value, valueToUid } = props;

  const handleChange = useCallback(
    (radioChangeEvent: RadioChangeEvent) => {
      if (onChange) {
        const foundOption: IOption<T> | undefined = options.find(
          (option: IOption<T>): boolean =>
            valueToUid(option.value) === radioChangeEvent.target.value,
        );

        if (foundOption !== undefined) {
          onChange(foundOption.value);
        }
      }
    },
    [onChange, options, valueToUid],
  );

  return (
    <AntDesignRadioGroup
      className={className}
      disabled={isDisabled}
      value={value}
    >
      {options.map((option: IOption<T>): JSX.Element => {
        const uid: string = valueToUid(option.value);

        return (
          <Radio
            disabled={option.isDisabled}
            key={uid}
            onChange={handleChange}
            value={uid}
          >
            {option.icon === undefined ? (
              option.tooltipLabel ? (
                <SeparatedRowLayout>
                  <div className='option-text'>{option.label}</div>
                  <Tooltip title={option.tooltipLabel} placement={'top'}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </SeparatedRowLayout>
              ) : (
                option.label
              )
            ) : (
              <SeparatedRowLayout>
                {option.icon}
                <div className='option-text'>{option.label}</div>
                <Tooltip title={'title'} placement={'top'}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </SeparatedRowLayout>
            )}
          </Radio>
        );
      })}
    </AntDesignRadioGroup>
  );
};

export default RadioGroup;
