import { EProfileTimeSplit, EProfileTimeUnit } from 'enums/Detail';
import { IOption } from 'interfaces/Component';

export const MODAL_WIDTH = '760px';

export const TIME_SPLIT_OPTIONS: IOption<EProfileTimeSplit>[] = [
  {
    label: 'Daily',
    value: EProfileTimeSplit.Daily,
  },
  {
    label: 'Hourly',
    value: EProfileTimeSplit.Hourly,
  },
  {
    label: '15-min',
    value: EProfileTimeSplit.Fifteen,
    tooltipLabel:
      'Number of hours in 15-minute Granularity from the start date; remaining time will be hourly. No entry defaults to 15-min for the entire date range.\n',
  },
  {
    label: '5-min',
    value: EProfileTimeSplit.Five,
    tooltipLabel:
      'Number of hours in 5-minute Granularity from the start date; remaining time will be hourly. No entry defaults to 5-min for the entire date range.\n',
  },
  {
    label: 'Custom Fixed Duration',
    value: EProfileTimeSplit.FixedDuration,
  },
];

export const TIME_UNIT_OPTIONS: IOption<EProfileTimeUnit>[] = [
  {
    label: 'Min(s)',
    value: EProfileTimeUnit.Minute,
  },
  {
    label: 'Hour(s)',
    value: EProfileTimeUnit.Hour,
  },
  {
    label: 'Day(s)',
    value: EProfileTimeUnit.Day,
  },
];

export const START_STOP_PLACEHOLDERS: [string, string] = [
  'Start date time',
  'Stop date time',
];
