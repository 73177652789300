import { ProfileFilled, ProfileOutlined } from '@ant-design/icons';
import Checkbox from 'components/atoms/Checkbox/Checkbox';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import ToggleSwitch from 'components/atoms/ToggleSwitch/ToggleSwitch';
import RequestIndicator from 'components/molecules/RequestIndicator/RequestIndicator';
import Select, { ISelectProps } from 'components/molecules/Select/Select';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import DetailAdHocProfileSplit from 'components/organisms/DetailAdHocSplitProfile/DetailAdHocProfileSplit';
import ProductProfileSelection from 'components/organisms/ProductProfileSelection/ProductProfileModal';
import {
  ALL_PROFILES_FORMAT_OPTIONS,
  EDIT_ETAG_DRAFT_TABLE_CONFIGURATION_OPTIONS,
  ENERGY_SNAPSHOT_FORMAT_OPTIONS,
  PROFILE_FORMAT_SELECT_WIDTH,
  PROFILE_SEGMENT_OPTIONS,
  PROFILE_VIEW_OPTIONS,
  REDUCED_ALL_PROFILES_FORMAT_OPTIONS,
  REDUCED_PROFILE_FORMAT_SELECT_WIDTH,
  REVIEW_TABLE_CONFIGURATION_OPTIONS,
} from 'components/organisms/ProfileInformationView/ProfileInformationManager/constants';
import SameEnergyProfilesCheckbox from 'components/organisms/ProfileInformationView/SameEnergyProfilesCheckbox/SameEnergyProfilesCheckbox';
import { EProfileView } from 'components/organisms/ProfileInformationView/types';
import { EProfileFormat, ETableConfiguration } from 'enums/Detail';
import { EProfileSegment } from 'enums/ETag';
import { EViewMode } from 'enums/View';
import { IOption } from 'interfaces/Component';
import { IAdHocProfile, IProfileInterval } from 'interfaces/Detail';
import { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TToEntityId } from 'types/ToEntity';
import { getIsReviewingProfileDataGrid } from 'utils/detail';
import { encodeIds } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

// Specialize the Select component
const RequestIdSelect = styled((props: ISelectProps<string>) =>
  Select<string>(props),
)`
  width: 301px;
`;

const ProfileViewSelect = styled((props: ISelectProps<EProfileView>) =>
  Select<EProfileView>(props),
)`
  width: 137px;
`;

interface IProfileFormatSelectProps extends ISelectProps<EProfileFormat> {
  width: string;
}

const ProfileFormatSelect = styled((props: ISelectProps<EProfileFormat>) =>
  Select<EProfileFormat>(props),
)<IProfileFormatSelectProps>`
  width: ${(props) => props.width};
`;

const ProfileDateSelect = styled((props: ISelectProps<ZonedDateTime>) =>
  Select<ZonedDateTime>(props),
)`
  width: 107px;
`;

const TableConfigurationSelect = styled(
  (props: ISelectProps<ETableConfiguration>) =>
    Select<ETableConfiguration>(props),
)`
  width: 223px;
`;

const DisplayCountSelect = styled((props: ISelectProps<number>) =>
  Select<number>(props),
)`
  width: 59px;
`;

const ProfileSegmentSelect = styled((props: ISelectProps<EProfileSegment>) =>
  Select<EProfileSegment>(props),
)`
  width: 73px;
`;

interface IProfileInformationManagerActionBarProps {
  className?: string;
  displayCountOptions: IOption<number>[];
  encodedPermissionsId: string;
  isDisabled?: boolean;
  isSameTransAllocProfile: boolean;
  isViewFull: boolean;
  onAdHocProfileSplitApply: (adHocProfile: IAdHocProfile) => void;
  onProductProfileApply: (combinedIntervals: IProfileInterval[]) => void;
  profileDateOptions: IOption<ZonedDateTime>[];
  requestIdOptions: IOption<string>[];
  selectedDisplayCount: number | undefined;
  selectedProfileDate: ZonedDateTime | undefined;
  selectedProfileFormat: EProfileFormat | undefined;
  selectedProfileSegment: EProfileSegment | undefined;
  selectedProfileView: EProfileView | undefined;
  selectedRequestKey: string | undefined;
  selectedTableConfiguration: ETableConfiguration | undefined;
  setIsSameTransAllocProfile: (isSameTransAllocProfile: boolean) => void;
  setSelectedDisplayCount: (displayCount: number | undefined) => void;
  setSelectedProfileDate: (profileDate: ZonedDateTime | undefined) => void;
  setSelectedProfileFormat: (profileFormat: EProfileFormat) => void;
  setSelectedProfileSegment: (
    profileSegment: EProfileSegment | undefined,
  ) => void;
  setSelectedProfileView: (profileView: EProfileView | undefined) => void;
  setSelectedRequestKey: (selectedRequestKey: string) => void;
  setSelectedTableConfiguration: (
    selectedTableConfiguration: ETableConfiguration | undefined,
  ) => void;
  setShowReliability: (showReliability: boolean) => void;
  showReliability: boolean | undefined;
  startDate: string | null;
  endDate: string | null;
  timeZone: TTimeZone;
  toEntityId: TToEntityId;
  viewMode: EViewMode;
}

const ProfileInformationManagerActionBar = ({
  className,
  displayCountOptions,
  encodedPermissionsId,
  isDisabled,
  isSameTransAllocProfile,
  isViewFull,
  onAdHocProfileSplitApply,
  onProductProfileApply,
  profileDateOptions,
  requestIdOptions,
  selectedDisplayCount,
  selectedProfileDate,
  selectedProfileFormat,
  selectedProfileSegment,
  selectedProfileView,
  selectedRequestKey,
  selectedTableConfiguration,
  setIsSameTransAllocProfile,
  setSelectedDisplayCount,
  setSelectedProfileDate,
  setSelectedProfileFormat,
  setSelectedProfileView,
  setSelectedProfileSegment,
  setSelectedRequestKey,
  setSelectedTableConfiguration,
  setShowReliability,
  showReliability,
  startDate,
  endDate,
  timeZone,
  toEntityId,
  viewMode,
}: IProfileInformationManagerActionBarProps): JSX.Element => {
  const isReviewing: boolean = useMemo(
    () => getIsReviewingProfileDataGrid(viewMode),
    [viewMode],
  );

  const tableConfigurationOptions: IOption<ETableConfiguration>[] = useMemo(
    () =>
      viewMode === EViewMode.EditETagDraft
        ? EDIT_ETAG_DRAFT_TABLE_CONFIGURATION_OPTIONS
        : REVIEW_TABLE_CONFIGURATION_OPTIONS,
    [viewMode],
  );

  const isNotEditableProfileFormat: boolean =
    selectedProfileFormat !== EProfileFormat.StartStop;

  const handleRequestKeyChange = (requestKey: string | undefined) => {
    if (requestKey === undefined) {
      throw new Error('Invalid request id selected.');
    } else {
      setSelectedRequestKey(requestKey);
    }
  };

  const handleProfileFormatChange = (format: EProfileFormat | undefined) => {
    if (format === undefined) {
      throw new Error('Invalid format selected');
    }
    setSelectedProfileFormat(format as EProfileFormat);
  };

  useEffect(() => {
    if (selectedProfileView === EProfileView.Snapshots) {
      setSelectedProfileFormat(EProfileFormat.StartStop);
    }
  }, [selectedProfileView, setSelectedProfileFormat]);

  useEffect(() => {
    if (isViewFull) {
      setSelectedTableConfiguration(ETableConfiguration.ProfileOnly);
    }
  }, [isViewFull, setSelectedTableConfiguration]);

  return (
    <Layout>
      <SeparatedRowLayout className={className}>
        {isReviewing || viewMode === EViewMode.EditETagCorrection ? (
          isViewFull ? (
            <Tooltip title='Request Id Selection'>
              <RequestIdSelect
                isDisabled={isDisabled}
                onChange={handleRequestKeyChange}
                options={requestIdOptions}
                value={selectedRequestKey}
                valueToUid={(value: string): string => value}
              />
            </Tooltip>
          ) : (
            <RequestIndicator requestKey={selectedRequestKey} />
          )
        ) : null}
      </SeparatedRowLayout>
      <SeparatedRowLayout className={className}>
        {viewMode === EViewMode.EditETagDraft ||
        viewMode === EViewMode.EditETagTemplate ? (
          <>
            <ProductProfileSelection
              encodedPermissionsId={encodeIds([
                encodedPermissionsId,
                'detailProductProfiles',
              ])}
              isDisabled={isNotEditableProfileFormat}
              onApply={onProductProfileApply}
              startDate={startDate}
              timeZone={timeZone}
              toEntityId={toEntityId}
            />
            <DetailAdHocProfileSplit
              encodedPermissionsId={encodeIds([
                encodedPermissionsId,
                'detailAdHocProfileSplit',
              ])}
              isDisabled={isNotEditableProfileFormat}
              onApply={onAdHocProfileSplitApply}
              startDate={startDate}
              endDate={endDate}
              timeZone={timeZone}
              viewMode={viewMode}
            />
            <Tooltip
              isDisabled={isDisabled || isNotEditableProfileFormat}
              title={
                isSameTransAllocProfile
                  ? 'Uncheck to Stop Auto Populating of OASIS Contract Profile with Energy Profile from Generation Segment'
                  : 'Check to Auto Populate OASIS Contract Profile with Energy Profile from Generation Segment'
              }
            >
              <Checkbox
                checked={isSameTransAllocProfile}
                isDisabled={isDisabled || isNotEditableProfileFormat}
                label='Same TransAlloc Profile'
                onChange={setIsSameTransAllocProfile}
              />
            </Tooltip>
            <SameEnergyProfilesCheckbox
              isDisabled={isDisabled || isNotEditableProfileFormat}
            />
          </>
        ) : null}
        {selectedProfileView === EProfileView.Profiles ? (
          <>
            {isReviewing ? (
              <Tooltip title='Show Reliability Level Profile Column'>
                <ToggleSwitch
                  checkedChildren={<ProfileFilled />}
                  isChecked={showReliability}
                  isDisabled={isDisabled}
                  onChange={setShowReliability}
                  unCheckedChildren={<ProfileOutlined />}
                />
              </Tooltip>
            ) : null}
            {isViewFull ? null : (
              <Tooltip title='Table Configuration Selection'>
                <TableConfigurationSelect
                  isDisabled={
                    isDisabled ||
                    viewMode === EViewMode.None ||
                    viewMode === EViewMode.EditETagDraft ||
                    viewMode === EViewMode.EditETagTemplate
                  }
                  onChange={setSelectedTableConfiguration}
                  options={tableConfigurationOptions}
                  placeholder='Select Table Configuration'
                  value={selectedTableConfiguration}
                  valueToUid={(value: ETableConfiguration): string =>
                    value as string
                  }
                />
              </Tooltip>
            )}
          </>
        ) : null}
        {selectedProfileView === EProfileView.Snapshots ? (
          <>
            <Tooltip title='Display Count Selection'>
              <DisplayCountSelect
                isDisabled={isDisabled}
                onChange={setSelectedDisplayCount}
                options={displayCountOptions}
                placeholder='Select Display Count'
                value={selectedDisplayCount}
                valueToUid={(value: number): string => value.toString()}
              />
            </Tooltip>
            <Tooltip title='Profile Segment Selection'>
              <ProfileSegmentSelect
                isDisabled={isDisabled}
                onChange={setSelectedProfileSegment}
                options={PROFILE_SEGMENT_OPTIONS}
                placeholder='Select Profile Segment'
                value={selectedProfileSegment}
                valueToUid={(value: EProfileSegment): string => value as string}
              />
            </Tooltip>
          </>
        ) : null}
        <Tooltip title='Scroll to Date'>
          <ProfileDateSelect
            isDisabled={isDisabled}
            onChange={setSelectedProfileDate}
            options={profileDateOptions}
            placeholder='Select Date'
            value={selectedProfileDate}
            valueToUid={(value: ZonedDateTime): string =>
              value.valueOf().toString()
            }
          />
        </Tooltip>
        <Tooltip title='Profile Format Selection'>
          <ProfileFormatSelect
            isDisabled={isDisabled}
            onChange={handleProfileFormatChange}
            options={
              selectedProfileView === EProfileView.Snapshots
                ? ENERGY_SNAPSHOT_FORMAT_OPTIONS
                : isReviewing
                ? ALL_PROFILES_FORMAT_OPTIONS
                : REDUCED_ALL_PROFILES_FORMAT_OPTIONS
            }
            placeholder='Select Profile Format'
            value={selectedProfileFormat}
            valueToUid={(value: EProfileFormat): string => value as string}
            width={
              isReviewing
                ? PROFILE_FORMAT_SELECT_WIDTH
                : REDUCED_PROFILE_FORMAT_SELECT_WIDTH
            }
          />
        </Tooltip>
        <Tooltip title='Profile View Selection'>
          <ProfileViewSelect
            isDisabled={isDisabled || !isReviewing}
            onChange={setSelectedProfileView}
            options={PROFILE_VIEW_OPTIONS}
            placeholder='Select Profile View'
            value={selectedProfileView}
            valueToUid={(value: EProfileView): string => value as string}
          />
        </Tooltip>
      </SeparatedRowLayout>
    </Layout>
  );
};

export default ProfileInformationManagerActionBar;
