import { FileSyncOutlined } from '@ant-design/icons';
import { Card as AntDesignCard } from 'antd';
import IconButton from 'components/atoms/IconButton/IconButton';
import { EFloatOverPlacement } from 'components/molecules/FloatOver/FloatOver';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import ChangeRequest from 'components/organisms/DetailChangeRequest/ChangeRequest';
import {
  CHANGE_REQUEST_CONTACT_INFO_EDIT_LABEL,
  CHANGE_REQUEST_MAXIMUM_HEIGHT,
  CHANGE_REQUEST_MISC_INFOS_EDIT_LABEL,
} from 'components/organisms/DetailChangeRequest/constants';
import { NO_OP_HANDLER } from 'constants/General';
import {
  BUTTON_ICON_DIMENSIONS,
  STANDARD_SPACING_VALUE,
} from 'constants/styles';
import {
  FloatOverContext,
  IFloatOverContext,
} from 'contexts/FloatOver/FloatOver';
import { EDistributedTagItem } from 'enums/ETag';
import { ERetreiveState } from 'enums/General';
import { EPageMode } from 'enums/Page';
import { EViewMode } from 'enums/View';
import useContactInfoEditable from 'hooks/useContactInfoEditable';
import useInitialData from 'hooks/useInitialData';
import useMiscInfoEditable from 'hooks/useMiscInfoEditable';
import usePrevious from 'hooks/usePrevious';
import useToEntityId from 'hooks/useToEntityId';
import {
  IETagConfigResponse,
  IETagTransmissionAllocation,
  IETagTransmissionPhysicalSegment,
} from 'interfaces/ETag';
import { IContactInfo } from 'interfaces/General';
import {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  detailEditETagDetail,
  detailRetrieveETagDistributedTagItems,
  detailUpdateETagDetails,
} from 'reduxes/Detail/actions';
import {
  IDetailGenerationPhysicalSegment,
  IDetailLoadPhysicalSegment,
  IDetailLossAccounting,
  IDetailMarketSegment,
} from 'reduxes/Detail/types';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TErrorMessage } from 'types/Error';
import { TRootState } from 'types/Redux';
import { TToEntityId } from 'types/ToEntity';
import {
  cleanUpDetailStateProfileChanges,
  retrieveAndTransformDistributedTagTransactionStatuses,
} from 'utils/detail';
import { isEmptyValue } from 'utils/general';
import {
  getDetailToEntityUserSelectedTimeZone,
  getUserContactInfo,
} from 'utils/user';
import useAsyncEffect from 'use-async-effect';
import { AxiosResponse } from 'axios';
import { retrieveToEntityConfig } from '../../../services/configclient/config';

const RefreshIcon = styled(FileSyncOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const retrieveChangeRequestState = (state: TRootState) => {
  const {
    isDetailDeleted,
    generationPhysicalSegment,
    loadPhysicalSegment,
    lossAccountings,
    marketSegments,
    pageMode,
    physicalSegmentsProfiles,
    profileChanges,
    retrievingDetail,
    selectedRequestType,
    tag_id,
    tag_primary_key,
    toEntity,
    transmission_physical_segments,
    transmissionAllocations,
    useUniqueProfiles,
    show_losses,
  } = state.detail.present;

  const userContactInfo: IContactInfo | undefined = getUserContactInfo(state);
  const isDetailLoading: boolean =
    retrievingDetail !== ERetreiveState.NotRetrieving &&
    retrievingDetail !== ERetreiveState.RetrievingCompleted;
  const timeZone: TTimeZone = getDetailToEntityUserSelectedTimeZone(state);

  return {
    generationPhysicalSegment,
    isDetailDeleted,
    isDetailLoading,
    loadPhysicalSegment,
    lossAccountings,
    marketSegments,
    pageMode,
    physicalSegmentsProfiles,
    profileChanges,
    selectedRequestType,
    tag_id,
    tag_primary_key,
    timeZone,
    toEntity,
    transmission_physical_segments,
    transmissionAllocations,
    userContactInfo,
    useUniqueProfiles,
    show_losses,
  };
};

interface IDetailChangeRequestProps {
  encodedPermissionsId: string;
  id: string;
  isDetailDeleted: boolean;
  isDetailUpdating: boolean;
  isDisabled?: boolean;
  previousIsDetailDeleted: boolean | undefined;
  previousIsDetailUpdating: boolean | undefined;
  securityKey: string | undefined;
  viewMode: EViewMode;
  onRefresh: () => Promise<void>;
}

const DetailChangeRequest = ({
  encodedPermissionsId,
  id,
  isDetailDeleted,
  isDetailUpdating,
  isDisabled,
  previousIsDetailDeleted,
  previousIsDetailUpdating,
  securityKey,
  viewMode,
  onRefresh,
}: IDetailChangeRequestProps): JSX.Element => {
  const {
    floatOverContent,
    floatOverId,
    setFloatOverContent,
    setFloatOverDefaultPosition,
    setFloatOverId,
    setFloatOverMaximumHeight,
    setFloatOverUseDragPanel,
  } = useContext<IFloatOverContext>(FloatOverContext);
  const dispatch = useDispatch();
  const {
    generationPhysicalSegment,
    isDetailLoading,
    loadPhysicalSegment,
    lossAccountings,
    marketSegments,
    pageMode,
    physicalSegmentsProfiles,
    profileChanges,
    selectedRequestType,
    tag_id,
    tag_primary_key,
    timeZone,
    toEntity,
    transmission_physical_segments,
    transmissionAllocations,
    userContactInfo,
    useUniqueProfiles,
  } = useSelector(retrieveChangeRequestState);
  const isCorrection: boolean = viewMode === EViewMode.EditETagCorrection;
  const isProfileChange: boolean =
    viewMode === EViewMode.EditETagAdjustment ||
    viewMode === EViewMode.EditETagAdjustmentWithATF;
  const [notes, setNotes] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<TErrorMessage>(null);
  const [isValidating, setIsValidating] = useState<boolean>(false);
  const [isValidateSuccessful, setIsValidateSuccessful] =
    useState<boolean>(false);
  const [isValidateAndSubmitSuccessful, setIsValidateAndSubmitSuccessful] =
    useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [validationWarnings, setValidationWarnings] = useState<string[]>([]);
  const [skipValidation, setSkipValidation] = useState<boolean>(false);
  const buttonRef = useRef<HTMLElement>() as MutableRefObject<HTMLElement>;
  const previousIsDetailLoading: boolean | undefined =
    usePrevious(isDetailLoading);
  const previousPageMode: EPageMode | undefined = usePrevious(pageMode);
  const previousIsValidating: boolean | undefined = usePrevious(isValidating);
  const isDetailDisabled: boolean =
    isDetailDeleted || isDetailUpdating || isValidating;
  const hasDetailChanged: boolean =
    isDetailDeleted !== previousIsDetailDeleted ||
    isDetailUpdating !== previousIsDetailUpdating ||
    isValidating !== previousIsValidating;

  const [showLossesConfig, setShowLossesConfig] = useState<boolean | undefined>(
    false,
  );

  const { contactInfoEditableProps, contactInfo, isFaxValid, isPhoneValid } =
    useContactInfoEditable(
      CHANGE_REQUEST_CONTACT_INFO_EDIT_LABEL,
      isDetailDisabled,
      hasDetailChanged,
      userContactInfo,
    );

  const { miscInfoEditableProps, miscInfos } = useMiscInfoEditable(
    CHANGE_REQUEST_MISC_INFOS_EDIT_LABEL,
    isDetailDisabled,
    hasDetailChanged,
  );

  const hasChanged = useCallback(
    (): boolean =>
      (isDetailLoading === false && previousIsDetailLoading === true) ||
      previousPageMode !== pageMode ||
      (isDetailUpdating === false && previousIsDetailUpdating === true),
    [
      isDetailLoading,
      isDetailUpdating,
      pageMode,
      previousIsDetailLoading,
      previousIsDetailUpdating,
      previousPageMode,
    ],
  );

  const hasChangedConst: boolean =
    (isDetailLoading === false && previousIsDetailLoading === true) ||
    previousPageMode !== pageMode ||
    (isDetailUpdating === false && previousIsDetailUpdating === true);

  const initialMarketSegments: IDetailMarketSegment[] | null | undefined =
    useInitialData(marketSegments, hasChanged);

  const initialGenerationPhysicalSegment:
    | IDetailGenerationPhysicalSegment
    | null
    | undefined = useInitialData(generationPhysicalSegment, hasChanged);

  const initialTransmissionPhysicalSegments:
    | IETagTransmissionPhysicalSegment[]
    | null
    | undefined = useInitialData(transmission_physical_segments, hasChanged);

  const initialLoadPhysicalSegment:
    | IDetailLoadPhysicalSegment
    | null
    | undefined = useInitialData(loadPhysicalSegment, hasChanged);

  const initialLossAccountings: IDetailLossAccounting[] | undefined =
    useInitialData(lossAccountings, hasChanged);

  const [initialTransmissionAllocations, setInitialTransAllocations] = useState<
    IETagTransmissionAllocation[] | null
  >(null);

  useAsyncEffect(async () => {
    const retrieveToEntityConfigResponse: AxiosResponse<IETagConfigResponse> =
      await retrieveToEntityConfig(toEntityId);
    const eTagConfigResponse: IETagConfigResponse =
      retrieveToEntityConfigResponse.data;
    if (eTagConfigResponse && eTagConfigResponse.response) {
      setShowLossesConfig(eTagConfigResponse.response.show_losses);
    }
  }, []);

  useEffect(() => {
    // these need a special condition to update when the data first comes in
    if (
      (transmissionAllocations !== null &&
        initialTransmissionAllocations === null) ||
      hasChangedConst
    ) {
      setInitialTransAllocations(transmissionAllocations);
    }
  }, [
    hasChangedConst,
    initialTransmissionAllocations,
    transmissionAllocations,
  ]);

  const title: string = useMemo(
    () =>
      isProfileChange
        ? 'Request Profile Change'
        : isCorrection
        ? 'Request Correction'
        : '',
    [isCorrection, isProfileChange],
  );

  const toEntityId: TToEntityId = useToEntityId(toEntity);

  useEffect(() => {
    setErrorMessage(null);
    setSkipValidation(false);
  }, [
    contactInfo,
    generationPhysicalSegment,
    loadPhysicalSegment,
    lossAccountings,
    marketSegments,
    notes,
    transmissionAllocations,
    transmission_physical_segments,
  ]);

  const retrieveTransactionStatuses = useCallback(() => {
    dispatch(
      detailRetrieveETagDistributedTagItems([
        {
          detailStateLoadTransform:
            retrieveAndTransformDistributedTagTransactionStatuses(
              toEntityId,
              tag_primary_key!,
            ),
          distributedTagItem: EDistributedTagItem.TransactionStatuses,
        },
      ]),
    );

    dispatch(detailEditETagDetail({ isDetailEdited: false }));
  }, [dispatch, tag_primary_key, toEntityId]);

  const cleanUpProfileChanges = useCallback(() => {
    dispatch(
      detailEditETagDetail({
        // Although this is a clean up operation, we want to retain the current
        // isDetailEdited value. This should be true before this call is made
        // since we don't allow a cleanUpProfileChanges call to be made unless
        // the user has made profile changes (and any profile changes causes
        // the isDetailEdited value to be set to true).
        isDetailEdited: true,
        stateTransform: cleanUpDetailStateProfileChanges(),
      }),
    );
  }, [dispatch]);

  const clearETagDetailEdits = useCallback(() => {
    dispatch(detailUpdateETagDetails(NO_OP_HANDLER));
  }, [dispatch]);

  const FloatOverContent: JSX.Element = useMemo(
    () => (
      <AntDesignCard key={id} title={title}>
        <ChangeRequest
          cleanUpProfileChanges={cleanUpProfileChanges}
          clearETagDetailEdits={clearETagDetailEdits}
          contactInfo={contactInfo}
          contactInfoEditableProps={contactInfoEditableProps}
          errorMessage={errorMessage}
          generationPhysicalSegment={generationPhysicalSegment}
          initialGenerationPhysicalSegment={initialGenerationPhysicalSegment}
          initialLoadPhysicalSegment={initialLoadPhysicalSegment}
          initialLossAccountings={initialLossAccountings}
          initialMarketSegments={initialMarketSegments}
          initialTransmissionAllocations={initialTransmissionAllocations}
          initialTransmissionPhysicalSegments={
            initialTransmissionPhysicalSegments
          }
          isCorrection={isCorrection}
          isFaxValid={isFaxValid}
          isNotesDisabled={isDetailDisabled}
          isPhoneValid={isPhoneValid}
          isProfileChange={isProfileChange}
          isValidateAndSubmitSuccessful={isValidateAndSubmitSuccessful}
          isValidateSuccessful={isValidateSuccessful}
          isValidating={isValidating}
          loadPhysicalSegment={loadPhysicalSegment}
          lossAccountings={lossAccountings}
          marketSegments={marketSegments}
          miscInfoEditableProps={miscInfoEditableProps}
          miscInfos={miscInfos}
          notes={notes}
          physicalSegmentsProfiles={physicalSegmentsProfiles}
          profileChanges={profileChanges}
          retrieveTransactionStatuses={retrieveTransactionStatuses}
          securityKey={securityKey}
          selectedRequestType={selectedRequestType}
          setErrorMessage={setErrorMessage}
          setIsValidateSuccessful={setIsValidateSuccessful}
          setIsValidateAndSubmitSuccessful={setIsValidateAndSubmitSuccessful}
          setIsValidating={setIsValidating}
          setNotes={setNotes}
          setSkipValidation={setSkipValidation}
          setValidationErrors={setValidationErrors}
          setValidationWarnings={setValidationWarnings}
          skipValidation={skipValidation}
          tagId={tag_id}
          tagPrimaryKey={tag_primary_key}
          timeZone={timeZone}
          toEntityId={toEntityId}
          transmissionAllocations={transmissionAllocations}
          transmissionPhysicalSegments={transmission_physical_segments}
          useUniqueProfiles={useUniqueProfiles}
          validationErrors={validationErrors}
          validationWarnings={validationWarnings}
          showLosses={showLossesConfig}
          onRefresh={onRefresh}
        />
      </AntDesignCard>
    ),
    [
      cleanUpProfileChanges,
      clearETagDetailEdits,
      contactInfo,
      contactInfoEditableProps,
      errorMessage,
      generationPhysicalSegment,
      id,
      initialGenerationPhysicalSegment,
      initialLoadPhysicalSegment,
      initialLossAccountings,
      initialMarketSegments,
      initialTransmissionAllocations,
      initialTransmissionPhysicalSegments,
      isCorrection,
      isDetailDisabled,
      isFaxValid,
      isPhoneValid,
      isProfileChange,
      isValidateAndSubmitSuccessful,
      isValidateSuccessful,
      isValidating,
      loadPhysicalSegment,
      lossAccountings,
      marketSegments,
      miscInfoEditableProps,
      miscInfos,
      notes,
      physicalSegmentsProfiles,
      profileChanges,
      retrieveTransactionStatuses,
      securityKey,
      selectedRequestType,
      setErrorMessage,
      setIsValidateSuccessful,
      setIsValidateAndSubmitSuccessful,
      setIsValidating,
      setNotes,
      setValidationErrors,
      setValidationWarnings,
      skipValidation,
      tag_id,
      tag_primary_key,
      title,
      timeZone,
      toEntityId,
      transmissionAllocations,
      transmission_physical_segments,
      useUniqueProfiles,
      validationErrors,
      validationWarnings,
      showLossesConfig,
      onRefresh,
    ],
  );

  useEffect(() => {
    if (!isEmptyValue(floatOverContent) && floatOverId === id) {
      setFloatOverUseDragPanel(true);
      setFloatOverContent(FloatOverContent);
    }
  }, [
    floatOverContent,
    FloatOverContent,
    floatOverId,
    id,
    setFloatOverContent,
    setFloatOverUseDragPanel,
  ]);

  const handleClick = () => {
    if (isEmptyValue(floatOverContent) || floatOverId !== id) {
      const { height, x, y } = buttonRef.current.getBoundingClientRect();

      setFloatOverId(id);
      setFloatOverDefaultPosition({
        placement: EFloatOverPlacement.Left,
        x,
        y: y + height + STANDARD_SPACING_VALUE,
      });
      setFloatOverMaximumHeight(CHANGE_REQUEST_MAXIMUM_HEIGHT);
      setFloatOverUseDragPanel(true);
      setFloatOverContent(FloatOverContent);
    } else if (floatOverId === id) {
      setFloatOverContent(null);
    }
  };

  return (
    <Tooltip title={title}>
      <IconButton
        buttonRef={buttonRef}
        encodedPermissionsId={encodedPermissionsId}
        icon={<RefreshIcon />}
        isDisabled={isDisabled}
        onClick={handleClick}
      />
    </Tooltip>
  );
};

export default DetailChangeRequest;
