import InputNumber from 'components/atoms/InputNumber/InputNumber';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import DateTimeRangePicker from 'components/molecules/DateTimeRangePicker/DateTimeRangePicker';
import EditorFooter from 'components/molecules/EditorFooter/EditorFooter';
import Modal from 'components/molecules/Modal/Modal';
import RadioGroup, {
  IRadioGroupProps,
} from 'components/molecules/RadioGroup/RadioGroup';
import Select from 'components/molecules/Select/Select';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import {
  MODAL_WIDTH,
  START_STOP_PLACEHOLDERS,
  TIME_SPLIT_OPTIONS,
  TIME_UNIT_OPTIONS,
} from 'components/organisms/DetailAdHocSplitProfile/constants';
import { getShowTime } from 'components/organisms/DetailAdHocSplitProfile/helpers';
import {
  COLUMN_LAYOUT_SHARED_STYLES,
  INPUT_HEIGHT,
  STANDARD_SPACING,
  STANDARD_SPACING_VALUE,
} from 'constants/styles';
import { DATE_TIME_FORMAT } from 'constants/time';
import { EProfileTimeSplit, EProfileTimeUnit } from 'enums/Detail';
import { ERequestType } from 'enums/ETag';
import { IOption } from 'interfaces/Component';
import { IAdHocProfile, ICustomInterval } from 'interfaces/Detail';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { TTimeZone, TZonedDateTimeRange } from 'types/DateTime';
import { TErrorMessage } from 'types/Error';
import { getInitialCustomInterval, isValidProfileDateTime } from 'utils/detail';
import { captureError } from 'utils/error';
import { encodeIds, isEmptyValue } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';
import { EViewMode } from '../../../enums/View';
import Button from '../../atoms/Button/Button';

const CHILD_SPACING = `
  > :not(:last-child) {
    margin-bottom: ${(STANDARD_SPACING_VALUE * 3) / 4}px;
  }

  > :last-child {
    margin-top: ${(STANDARD_SPACING_VALUE * 3) / 2}px;
  }
`;

const RIGHT_COLUMN_CHILD_SPACING = `
  > :not(:last-child) {
    margin-bottom: ${(STANDARD_SPACING_VALUE * 3) / 4}px;
  }

  > :last-child {
    margin-top: ${STANDARD_SPACING_VALUE / 4}px;
  }
`;

const TimeSplitRadioGroup = styled(
  (props: IRadioGroupProps<EProfileTimeSplit>): JSX.Element => (
    <RadioGroup<EProfileTimeSplit> {...props} />
  ),
)`
  ${COLUMN_LAYOUT_SHARED_STYLES}
  ${CHILD_SPACING}
`;

const ModalLayout = styled.div`
  > :not(:last-child) {
    margin-bottom: ${STANDARD_SPACING};
  }
`;

const ProfileTimeLayout = styled(SeparatedRowLayout)`
  align-items: flex-start;
`;

const Customisations = styled.div`
  ${COLUMN_LAYOUT_SHARED_STYLES}
  ${RIGHT_COLUMN_CHILD_SPACING}

  flex: 1;
`;

const CustomisationsFiller = styled.div`
  height: 20px;
`;

const CustomisationsLabel = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;

  > div {
    height: ${INPUT_HEIGHT};
    padding-top: 4px;

    :nth-child(2) {
      padding-top: 8px;
    }
  }
`;

const FixedIntervalRow = styled(SeparatedRowLayout)`
  margin-top: -4px;
`;

const MessageSpan = styled.span`
  color: #1e90ff;
`;

interface IDetailAdHocProfileSplitProps {
  defaultStartDate?: string | null;
  encodedPermissionsId: string;
  isDisabled?: boolean;
  onApply: (adHocProfile: IAdHocProfile) => void;
  viewMode: EViewMode;
  requestType?: ERequestType;
  startDate?: string | null;
  endDate?: string | null;
  timeZone: TTimeZone;
}

const DetailAdHocProfileSplit = ({
  defaultStartDate,
  encodedPermissionsId,
  isDisabled,
  onApply,
  requestType,
  startDate,
  endDate,
  timeZone,
  viewMode,
}: IDetailAdHocProfileSplitProps): JSX.Element => {
  const currentDayEnd = ZonedDateTime.now(timeZone)
    .add(1, 'day')
    .withMinute(0)
    .withHour(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [profileDateTimeRange, setProfileDateTimeRange] =
    useState<TZonedDateTimeRange>(
      isEmptyValue(startDate)
        ? null
        : isEmptyValue(endDate)
        ? viewMode === EViewMode.EditETagTemplate ||
          viewMode === EViewMode.EditETagDraft ||
          viewMode === EViewMode.ReviewETagTemplate ||
          viewMode === EViewMode.ReviewETagDraft
          ? [ZonedDateTime.parseIso(startDate!, timeZone), null]
          : [ZonedDateTime.now(timeZone).withMinute(0), null]
        : viewMode === EViewMode.EditETagTemplate ||
          viewMode === EViewMode.EditETagDraft ||
          viewMode === EViewMode.ReviewETagTemplate ||
          viewMode === EViewMode.ReviewETagDraft
        ? [
            ZonedDateTime.parseIso(startDate!, timeZone),
            ZonedDateTime.parseIso(endDate!, timeZone),
          ]
        : [
            ZonedDateTime.now(timeZone).withMinute(0),
            ZonedDateTime.parseIso(endDate!, timeZone).isBefore(currentDayEnd)
              ? ZonedDateTime.parseIso(endDate!, timeZone)
              : currentDayEnd,
          ],
    );
  const [selectedTimeSplit, setSelectedTimeSplit] = useState<
    EProfileTimeSplit | undefined
  >(EProfileTimeSplit.Fifteen);
  const [selectedTimeUnit, setSelectedTimeUnit] = useState<
    EProfileTimeUnit | undefined
  >(EProfileTimeUnit.Minute);
  const [fixedDuration, setFixedDuration] = useState<number | null>(null);
  const [hoursFive, setHoursFive] = useState<number | null>(null);
  const [genFive, setGenFive] = useState<number | null>(null);
  const [hoursFifteen, setHoursFifteen] = useState<number | null>(null);
  const [genFifteen, setGenFifteen] = useState<number | null>(null);
  const [customIntervals, setCustomIntervals] = useState<ICustomInterval[]>([
    getInitialCustomInterval(),
  ]);
  const [errorMessage, setErrorMessage] = useState<TErrorMessage>(null);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(false);

  const [genHourly, setGenHourly] = useState<number | null>(null);
  const [genDaily, setGenDaily] = useState<number | null>(null);

  useEffect(() => {
    setIsConfirmDisabled(
      profileDateTimeRange === null ||
        profileDateTimeRange[0] === null ||
        profileDateTimeRange[1] === null,
    );
  }, [profileDateTimeRange]);

  const disabledDate = useCallback(
    (currentDateTime: ZonedDateTime | null): boolean =>
      startDate === undefined
        ? false
        : !isValidProfileDateTime(startDate, requestType, currentDateTime)
            .isValid,
    [requestType, startDate],
  );

  const handleClick = () => {
    setShowModal(true);
  };

  const handleSplitProfileValueChange = (_number: number | undefined) => {
    let validatedNumber: number | null = _number === undefined ? null : _number;

    // This must be a runtime check since the input box can return a string
    // even though we are typing as a number or undefined !!!
    if (typeof _number === 'string') {
      validatedNumber = _number === '' ? null : parseInt(_number, 10);
    }

    setFixedDuration(validatedNumber);
  };

  const handleHourFiveChange = (_number: number | undefined) => {
    let validatedNumber: number | null = _number === undefined ? null : _number;

    // This must be a runtime check since the input box can return a string
    // even though we are typing as a number or undefined !!!
    if (typeof _number === 'string') {
      validatedNumber = _number === '' ? null : parseInt(_number, 10);
    }

    setHoursFive(validatedNumber);
  };

  const handleHourFifteenChange = (_number: number | undefined) => {
    let validatedNumber: number | null = _number === undefined ? null : _number;

    // This must be a runtime check since the input box can return a string
    // even though we are typing as a number or undefined !!!
    if (typeof _number === 'string') {
      validatedNumber = _number === '' ? null : parseInt(_number, 10);
    }

    setHoursFifteen(validatedNumber);
  };

  const handleGenChange = (_number: number | undefined) => {
    let validatedNumber: number | null = _number === undefined ? null : _number;

    // This must be a runtime check since the input box can return a string
    // even though we are typing as a number or undefined !!!
    if (typeof _number === 'string') {
      validatedNumber = _number === '' ? null : parseInt(_number, 10);
    }

    switch (selectedTimeSplit) {
      case EProfileTimeSplit.Five:
        setGenFive(validatedNumber);
        break;
      case EProfileTimeSplit.Fifteen:
        setGenFifteen(validatedNumber);
        break;
      case EProfileTimeSplit.Hourly:
        setGenHourly(validatedNumber);
        break;
      case EProfileTimeSplit.Daily:
        setGenDaily(validatedNumber);
        break;
    }
  };

  const handleCancel = async () => {
    setShowModal(false);
    setSelectedTimeSplit(EProfileTimeSplit.Fifteen);
    setProfileDateTimeRange(
      isEmptyValue(startDate)
        ? null
        : isEmptyValue(endDate)
        ? viewMode === EViewMode.EditETagTemplate ||
          viewMode === EViewMode.EditETagDraft ||
          viewMode === EViewMode.ReviewETagTemplate ||
          viewMode === EViewMode.ReviewETagDraft
          ? [ZonedDateTime.parseIso(startDate!, timeZone), null]
          : [ZonedDateTime.now(timeZone).withMinute(0), null]
        : viewMode === EViewMode.EditETagTemplate ||
          viewMode === EViewMode.EditETagDraft ||
          viewMode === EViewMode.ReviewETagTemplate ||
          viewMode === EViewMode.ReviewETagDraft
        ? [
            ZonedDateTime.parseIso(startDate!, timeZone),
            ZonedDateTime.parseIso(endDate!, timeZone).isBefore(currentDayEnd)
              ? ZonedDateTime.parseIso(endDate!, timeZone)
              : currentDayEnd,
          ]
        : [
            ZonedDateTime.now(timeZone).withMinute(0),
            ZonedDateTime.parseIso(endDate!, timeZone).isBefore(currentDayEnd)
              ? ZonedDateTime.parseIso(endDate!, timeZone)
              : currentDayEnd,
          ],
    );
    setGenFive(null);
    setGenFifteen(null);
    setHoursFive(null);
    setHoursFifteen(null);
    setFixedDuration(null);
    setDateTimeRangeMessage('');
  };

  const handleApply = async () => {
    try {
      setErrorMessage(null);

      const filteredCustomIntervals: ICustomInterval[] = customIntervals.filter(
        (customInterval: ICustomInterval): boolean =>
          customInterval.time !== null,
      );

      let duration = hoursFive || hoursFifteen || fixedDuration;
      const genValue = genDaily || genHourly || genFive || genFifteen;

      if (
        !duration &&
        profileDateTimeRange &&
        profileDateTimeRange[0] &&
        profileDateTimeRange[1] &&
        selectedTimeSplit !== EProfileTimeSplit.Daily
      ) {
        duration = Math.ceil(
          (profileDateTimeRange[1] as ZonedDateTime)
            .withMinute(0)
            .withSeconds(0)
            .diff(
              (profileDateTimeRange[0] as ZonedDateTime)
                .withMinute(0)
                .withSeconds(0),
              'minutes',
            ) / 60,
        );
      }

      onApply({
        customIntervals: filteredCustomIntervals,
        fixedDuration: duration,
        startDateTime: profileDateTimeRange![0]!,
        stopDateTime: profileDateTimeRange![1]!,
        timeSplit: selectedTimeSplit,
        timeUnit: selectedTimeUnit,
        genValue,
      });

      if (filteredCustomIntervals.length > 0) {
        setCustomIntervals(filteredCustomIntervals);
      } else {
        setCustomIntervals([getInitialCustomInterval()]);
      }

      setShowModal(false);
    } catch (error: any) {
      captureError(error);

      setErrorMessage(error.message);
    }
  };

  const [dateTimeRangeMessage, setDateTimeRangeMessage] = useState<string>('');
  const handleSetSelectedTimeSplit = (split: EProfileTimeSplit | undefined) => {
    setSelectedTimeSplit(split);
    setGenDaily(null);
    setGenHourly(null);
    setGenFive(null);
    setGenFifteen(null);
    setHoursFive(null);
    setHoursFifteen(null);
    setFixedDuration(null);
  };

  const handleProfileDateTimeRange = (dateTimeRange: TZonedDateTimeRange) => {
    setDateTimeRangeMessage('');
    setProfileDateTimeRange(dateTimeRange);
    if (dateTimeRange && dateTimeRange[1] && endDate) {
      const selectedEndDate = (dateTimeRange[1] as ZonedDateTime).asDate();
      const tagEndDate = new Date(endDate);
      if (selectedEndDate.getTime() > tagEndDate.getTime()) {
        setDateTimeRangeMessage(
          'The selected end date is greater than the tag end date',
        );
      }
    }
  };

  const hasProfileDateRange: boolean =
    profileDateTimeRange !== null &&
    profileDateTimeRange.length === 2 &&
    profileDateTimeRange[0] !== null &&
    profileDateTimeRange[1] !== null;

  return (
    <>
      <Tooltip title={'Custom Profile Split'}>
        <Button
          isPrimary={true}
          isDisabled={isDisabled}
          onClick={handleClick}
          label={'Custom Profile Split'}
        />
      </Tooltip>
      <Modal
        footer={
          <EditorFooter
            confirmLabel='Apply'
            encodedPermissionsId={encodeIds([encodedPermissionsId, 'footer'])}
            errorMessage={errorMessage}
            isConfirmDisabled={isConfirmDisabled}
            onCancel={handleCancel}
            onConfirm={handleApply}
          />
        }
        isVisible={showModal}
        onCancel={handleCancel}
        title={'Custom Profile Split'}
        width={MODAL_WIDTH}
      >
        <ModalLayout>
          <SeparatedRowLayout>
            <div>Profile Date Time Range:</div>
            <DateTimeRangePicker
              allowClear={true}
              disabledDate={isEmptyValue(startDate) ? undefined : disabledDate}
              format={DATE_TIME_FORMAT}
              onChange={handleProfileDateTimeRange}
              placeholder={START_STOP_PLACEHOLDERS}
              showTime={getShowTime(timeZone)}
              timeZone={timeZone}
              value={profileDateTimeRange}
            />
            {dateTimeRangeMessage ? (
              <MessageSpan>{dateTimeRangeMessage}</MessageSpan>
            ) : null}
          </SeparatedRowLayout>
          <ProfileTimeLayout>
            <div>Profile Time Interval:</div>
            <TimeSplitRadioGroup
              isDisabled={!hasProfileDateRange}
              onChange={handleSetSelectedTimeSplit}
              options={TIME_SPLIT_OPTIONS}
              value={selectedTimeSplit}
              valueToUid={(value: EProfileTimeSplit): string => value as string}
            />
            <Customisations>
              {TIME_SPLIT_OPTIONS.map(
                (timeSplitOption: IOption<EProfileTimeSplit>): JSX.Element => {
                  switch (timeSplitOption.value) {
                    case EProfileTimeSplit.Daily:
                      return (
                        <FixedIntervalRow key={timeSplitOption.value}>
                          <InputNumber
                            isDisabled={
                              selectedTimeSplit !== EProfileTimeSplit.Daily
                            }
                            min={1}
                            onChange={handleGenChange}
                            value={genDaily}
                            placeholder={'MW'}
                          />
                        </FixedIntervalRow>
                      );
                    case EProfileTimeSplit.Hourly:
                      return (
                        <FixedIntervalRow key={timeSplitOption.value}>
                          <InputNumber
                            isDisabled={
                              selectedTimeSplit !== EProfileTimeSplit.Hourly
                            }
                            min={1}
                            onChange={handleGenChange}
                            value={genHourly}
                            placeholder={'MW'}
                          />
                        </FixedIntervalRow>
                      );
                    case EProfileTimeSplit.Five:
                      return (
                        <FixedIntervalRow key={timeSplitOption.value}>
                          <InputNumber
                            isDisabled={
                              selectedTimeSplit !== EProfileTimeSplit.Five
                            }
                            min={1}
                            onChange={handleHourFiveChange}
                            value={hoursFive}
                            placeholder={'Hours'}
                            max={24}
                          />
                          <InputNumber
                            isDisabled={
                              selectedTimeSplit !== EProfileTimeSplit.Five
                            }
                            min={1}
                            onChange={handleGenChange}
                            value={genFive}
                            placeholder={'MW'}
                          />
                        </FixedIntervalRow>
                      );
                    case EProfileTimeSplit.Fifteen:
                      return (
                        <FixedIntervalRow key={timeSplitOption.value}>
                          <InputNumber
                            isDisabled={
                              selectedTimeSplit !== EProfileTimeSplit.Fifteen
                            }
                            min={1}
                            onChange={handleHourFifteenChange}
                            value={hoursFifteen}
                            placeholder={'Hours'}
                            max={24}
                          />
                          <InputNumber
                            isDisabled={
                              selectedTimeSplit !== EProfileTimeSplit.Fifteen
                            }
                            min={1}
                            onChange={handleGenChange}
                            value={genFifteen}
                            placeholder={'MW'}
                          />
                        </FixedIntervalRow>
                      );
                    case EProfileTimeSplit.FixedDuration:
                      return (
                        <FixedIntervalRow key={timeSplitOption.value}>
                          <CustomisationsLabel>
                            <div>Split Profile Every</div>
                          </CustomisationsLabel>
                          <InputNumber
                            isDisabled={
                              selectedTimeSplit !==
                              EProfileTimeSplit.FixedDuration
                            }
                            min={1}
                            onChange={handleSplitProfileValueChange}
                            value={fixedDuration}
                          />
                          <Select
                            isDisabled={
                              selectedTimeSplit !==
                              EProfileTimeSplit.FixedDuration
                            }
                            onChange={setSelectedTimeUnit}
                            options={TIME_UNIT_OPTIONS}
                            value={selectedTimeUnit}
                            valueToUid={(value: EProfileTimeUnit): string =>
                              value as string
                            }
                          />
                        </FixedIntervalRow>
                      );
                    default:
                      return (
                        <CustomisationsFiller key={timeSplitOption.value} />
                      );
                  }
                },
              )}
            </Customisations>
          </ProfileTimeLayout>
        </ModalLayout>
      </Modal>
    </>
  );
};

export default DetailAdHocProfileSplit;
